#popup {
    opacity: 0;
    transition: 1s opacity;
    color : whitesmoke;
    position: fixed;
    left: 30%;
    bottom: 1vw;
    min-width: 40%;
    padding: 0.5vw;
    border-radius: 20px;
    text-align: center;
    z-index: 1;
    box-shadow: 0 8px 16px 0 rgba(112, 104, 104, 0.2);
  }