#header {
  text-align: center;
  background-color: #333333;
  font-size: 1.5rem;
  padding: 1rem;
  margin-bottom: 3vh;
}

#container-header {
  display: flex; 
  @media (max-width:767px){    
    flex-direction: column;
  }
  justify-content: space-around; 
  color: white;
}

#container-header .item {
  padding: 0.5rem;
}

a {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.active {
  background-color: #6eb5ff; 
  color: white;
  padding: 0.3rem 0.5rem;
}

