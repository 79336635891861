


// Auth service

#login-form {
    display : flex; 
    justify-content : space-around;
    flex-direction: column; 
    margin : auto;
    width: 50%;
    min-height : 30vh; 
}
